'use strict'

angular.module('cb.user-service', [])
    .factory('UserService', ["ApiService", "SessionService", "SwService", function (ApiService,SessionService,SwService) {

        var userService = {
            getAccountDetails: function () {
                try {
					var url = '/webapi/api/v1/user/GetCurrentUserDetail';
					return ApiService.promiseGet(url).then(AccountDetails=>{
						return AccountDetails;
					})

                }
                catch (e) {
                    console.error('Error!', e);
                }
			},
			FetchTimeSheets: function (data) {
				try {
					var url = '/webapi/api/v1/user/FetchTimeSheets';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			FetchTimeSheetById: function (data) {
				try {
					var url = '/webapi/api/v1/user/FetchTimeSheetById/' + data;
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			FetchTimeSheetAppointmentsById: function (data) {
				try {
					var url = '/webapi/api/v1/user/FetchTimeSheetAppointmentsById/' + data;
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			PopulateTimeSheet: function (data) {
				try {
					var url = '/webapi/api/v1/user/PopulateTimeSheet';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			CreateTimeSheet: function (data) {
				try {
					var url = '/webapi/api/v1/user/CreateTimeSheet';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			CreateTimeSheetApps: function (data) {
				try {
					var url = '/webapi/api/v1/user/CreateTimeSheetApps';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			CreateCustomTimeSheetApps: function (data) {
				try {
					var url = '/webapi/api/v1/user/CreateCustomTimeSheetApps';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			EditTimeSheet: function (data) {
				try {
					var url = '/webapi/api/v1/user/EditTimeSheet';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			EditTimeSheetApps: function (data) {
				try {
					var url = '/webapi/api/v1/user/EditTimeSheetApps';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			DeleteTimeSheet: function (data) {
				try {
					var url = '/webapi/api/v1/user/DeleteTimeSheet/' + data;
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			TimeSheetDailyCheck: function () {
				try {
					var url = '/webapi/api/v1/user/TimeSheetDailyCheck';
					return ApiService.promiseGet(url);
				}
				catch (e) {
					console.error('Error!', e);
				}
			
			},
			TimeSheetYesterdayCheck: function () {
				try {
					var url = '/webapi/api/v1/user/TimeSheetYesterdayCheck';
					return ApiService.promiseGet(url);
				}
				catch (e) {
					console.error('Error!', e);
				}

			},

			RemoveCustomTimeSheetEntry: function (data) {
				try {
					var url = '/webapi/api/v1/user/RemoveCustomTimeSheetEntry/'+ data;
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
            addHistoryItem: function (data) {
				try {
					var url = '/webapi/api/v1/history/create';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e)
				}
			}
        };

        return userService;
    }]);
